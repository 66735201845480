<template>
	<h1>Sub Navigation</h1>

	<div class="text-block bump-b-30">
		<pre v-highlightjs><code class="language-html">{{html}}</code></pre>

		<pre v-highlightjs><code class="language-javascript">import NavSub from '@/components/NavSub'

export default {
	components: { NavSub },
	data() {
		return {

			// Regular
			navItems1: [
				{
					display: 'Page One',
					to: {
						name: 'UiSubNavOne',
					},
				},
				{
					display: 'Page Two',
					to: {
						name: 'UiSubNavTwo',
					},
				},
			],

			// Non-routing
			navItems2: [
				{
					display: 'Waffles',
					onClick: () => {
						this.topping = 'Sugar'
					},
					sel: true,
				},
				{
					display: 'Fries',
					onClick: () => {
						this.topping = 'Mayonaise'
					},
				},
			],

			topping: '...'
		}
	},
}
</code></pre>
	</div>

	<div class="text-block bump-b-30">
		<h3>Implementation Regular</h3>
		<ul>
			<li>
				Create templates for each page and add them to the router as children.
			</li>
			<li>Add <span class="code">navItems {}</span> to data object.</li>
			<li>
				Include component and add 'navItems' as 'items' prop.
			</li>
		</ul>
	</div>

	<div class="text-block bump-b-30">
		<h3>Demo (Regular / Non-routing)</h3>
		<div id="demo-wrap">
			<div class="left">
				<NavSub :routed="true" :items="navItems1" />
				<router-view />
			</div>
			<div class="right">
				<NavSub :items="navItems2" />
				Topping: {{ topping }}
			</div>
		</div>
	</div>
</template>

<script>
import NavSub from '@/components/NavSub'
import { injectMetaData } from '@/use/MetaData'

export default {
	name: 'DocComponentsSubNav',
	components: { NavSub },
	setup() {
		injectMetaData({
			title: 'Doc: Sub Nav Component',
		})
	},
	data() {
		return {
			html: [
				'<!-- Regular sub navigation (uses router) -->',
				'<NavSub :routed="true" :items="navItems1" />',
				'<router-view />',
				'',
				'<!-- Non-router sub navigation (triggers event) -->',
				'<NavSub :items="navItems2" />',
			].join('\n'),
			navItems1: [
				{
					display: 'Page One',
					to: {
						name: 'DocComponentsSubNavOne',
					},
				},
				{
					display: 'Page Two',
					to: {
						name: 'DocComponentsSubNavTwo',
					},
				},
			],
			navItems2: [
				{
					display: 'Waffles',
					onClick: () => {
						this.topping = 'Sugar'
					},
					sel: true,
				},
				{
					display: 'Fries',
					onClick: () => {
						this.topping = 'Mayonaise'
					},
				},
			],
			topping: '...',
		}
	},
}
</script>

<style scoped lang="scss">
#demo-wrap {
	display: flex;
	gap: 0.2rem;
}
#demo-wrap > div {
	border: solid 0.01rem $black-10;
	padding: 0.4rem;
	border-radius: $br;
	flex: 1;
}
</style>
